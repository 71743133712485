import React, { useEffect, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

import "./app.scss";
import { db } from "./firebase";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const App = () => {
  const [fingerprint, setFingerprint] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (userName === "" && localStorage.getItem("user-id") !== null) {
      setUserName(localStorage.getItem("user-id"));
    }
  }, [userName]);

  useEffect(() => doStuff(), []);

  const doStuff = async () => {
    const fpPromise = await FingerprintJS.load();
    fpPromise.get().then((result) => {
      const { screenResolution, screenFrame, ...components } =
        result.components;

      console.info(components);

      console.info(FingerprintJS.hashComponents(components));
      setFingerprint(FingerprintJS.hashComponents(components));
    });
  };

  const sendToFirestore = (fingerprint, username) => {
    localStorage.setItem("user-id", username);
    if (fingerprint !== "" && userName !== "") {
      getDoc(doc(db, "fingerprints", username)).then((snapshot) => {
        // Check if user exists
        if (snapshot.exists()) {
          console.info("sending to firestore");
          updateDoc(doc(db, "fingerprints", username), {
            fingerprint: arrayUnion(fingerprint),
          });
        } else {
          console.info("sending to firestore");
          setDoc(doc(db, "fingerprints", username), {
            fingerprint: arrayUnion(fingerprint),
          });
        }
      });
      console.info("added in firestore");
    }
  };

  return (
    <div className="app">
      <input
        placeholder="Please share your user key"
        type="text"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
      <h1>{fingerprint ?? "Loading"}</h1>

      <button onClick={() => sendToFirestore(fingerprint, userName)}>
        Send To Firestore
      </button>
    </div>
  );
};

export default App;
