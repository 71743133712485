// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBU705_WS1748E7P0WqO9wS4UkFdPIU2qI",
  authDomain: "fingerprinting-poc.firebaseapp.com",
  projectId: "fingerprinting-poc",
  storageBucket: "fingerprinting-poc.appspot.com",
  messagingSenderId: "820123520354",
  appId: "1:820123520354:web:6f2db8e3abca908b74f03b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);